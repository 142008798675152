const CopyrightComponent = () => {
    return (
        <div className="container">
            <div className="copyright">
                &copy; Copyright: All rights reserved for <strong><span>{process.env.REACT_APP_APP_NAME}</span></strong> company.
            </div>
        </div>
    )
}

export default CopyrightComponent;