import { useContext, useEffect } from "react";
import AuthService from "../../../../services/AuthService";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../context/authContext";

const Logout = (props) => {
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);

  useEffect(() => {
    AuthService.logout();
    authContext.setLoggedIn(false);
    authContext.setAdminLoggedIn(false);
    if (props?.redirect) navigate("/admin/auth/login");
    else navigate("/signin");
  });

  return "";
};

export default Logout;
