import { MainComponent } from "../main";
import { Loading, SectionTitleComponent } from "../general";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import { ShowErrors } from "../../../../helpers/extractErrors";
import AuthService from "../../../../services/AuthService";

const MyServices = () => {
  const [code, setCode] = useState("");
  const navigate = useNavigate();
  const [waiting, setWaiting] = useState(false);
  const [fail, setFail] = useState(null);
  const { state } = useLocation();

  const handleSubmit = (event) => {
    event.preventDefault();

    setWaiting(true);
    setFail(null);

    const data = {
      code,
    };

    AuthService.verify(data)
      .then(
        (response) => {
          navigate("/dashboard");
        },
        (error) => {
          const response = error.response;
          setFail(response.data);
        }
      )
      .finally(() => {
        setWaiting(false);
      });
  };

  return (
    <div className="hero-replace">
      <div className="container">
        <SectionTitleComponent>Confirm your account</SectionTitleComponent>
        {<p>{state?.message}</p>}
        <div className="row">
          <MainComponent>
            <div
              className="col-lg-8 mt-5 mt-lg-0"
              data-aos="fade-left"
              data-aos-delay="200"
            >
              <form
                method="post"
                role="form"
                className="react-form"
                onSubmit={handleSubmit}
              >
                <div className="row">
                  <div className="col-md-6 form-group">
                    <input
                      type="text"
                      name="code"
                      className="form-control"
                      id="code"
                      placeholder="Code"
                      required
                      value={code}
                      onChange={(e) => setCode(e.target.value)}
                    />
                  </div>
                </div>
                <div className="mt-3">
                  <Loading waiting={waiting}/>
                  <ShowErrors fail={fail} />
                </div>
                <div className="text-right mt-3">
                  <button className="btn btn-primary" type="submit">
                    Confirm
                  </button>
                </div>
              </form>
            </div>
          </MainComponent>
        </div>
      </div>
    </div>
  );
};

export default MyServices;
