import {
  BrowserRouter, Route, Routes,
} from "react-router-dom";

import { useEffect } from "react";
import useState from "react-usestateref";
import AuthService from "../../services/AuthService";
import { AuthContext } from "../context/authContext";

//history
import { history } from "../../helpers/history";

import {AppRoutes} from "../layouts/route-manager";

function App() {
  const [isVerified, setIsVerified] = useState(false);
  const [loggedIn, setLoggedIn, setLoggedInRef] = useState(false);
  const [adminLoggedIn, setAdminLoggedIn, setAdminLoggedInRef] = useState(false);
  const [user, setUser] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);

    const prepareData = async() => {
      const [_isVerified, _loggedIn, _user, _adminLoggedIn] = await Promise.all([
        AuthService.isVerified(),
        AuthService.getJwtToken() ? true : false,
        AuthService.getCurrentUser(),
        AuthService.getAdminJwtToken() ? true : false,
      ]);

      setIsVerified(_isVerified);
      setLoggedIn(_loggedIn);
      setAdminLoggedIn(_adminLoggedIn);
      setUser(_user);
      setLoading(false);
    }

    prepareData();
  }, []);

  if (loading) return null;

  return (
      <AuthContext.Provider value={{ loggedIn, setLoggedIn, setLoggedInRef, user, setUser, adminLoggedIn, setAdminLoggedIn, setAdminLoggedInRef }}>
      <BrowserRouter history={history}>
          <AppRoutes/>
      </BrowserRouter>
      </AuthContext.Provider>
  );
}

export default App;
