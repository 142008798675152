import { HeroComponent } from "../hero";
import { MainComponent } from "../main";
import { AboutComponent } from "../main/sections/about";
import { FeaturesComponent } from "../main/sections/features";
import { CountsComponent } from "../main/sections/counts";
import { DetailsComponent } from "../main/sections/details";
import { GalleryComponent } from "../main/sections/gallery";
import { TestimonialsComponent } from "../main/sections/testimonials";
import { TeamComponent } from "../main/sections/team";
import { PricingComponent } from "../main/sections/pricing";
import { FaqComponent } from "../main/sections/faq";
import { ContactComponent } from "../main/sections/contact";

const Home = () => {
  return (
    <>
      <HeroComponent />
      <MainComponent>
        <AboutComponent />
        <FeaturesComponent />
        <CountsComponent />
        {/* <DetailsComponent /> */}
        <GalleryComponent />
        {/* <TestimonialsComponent /> */}
        {/* <TeamComponent /> */}
        <PricingComponent />
        {/* <FaqComponent /> */}
        {/* <ContactComponent /> */}
      </MainComponent>
    </>
  );
};

export default Home;
