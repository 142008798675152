import React from "react";

export const slug = (text) => {
    return text.replace(/ /g, '-');
}

export const Option = (props) => {
    var opts = {};
    opts['value'] = props.value;
    if (props.selected === true) 
        opts['selected'] = true;

    return (
        <option {...opts}>{props.label}</option>
    )
};