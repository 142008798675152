import { Slider } from "@mui/material"

const UserComponent = ({step, min, max, handleUser, label}) => {
    return (
        <div className="row mt-3 g-3 align-items-center">
            <div className="col-auto">
            <label className="col-form-label">{label}</label>
            </div>
            <div className="col-md-6">
            <Slider
                aria-label="users"
                valueLabelDisplay="auto"
                defaultValue={min}
                step={step}
                onChange={handleUser}
                marks
                min={min}
                max={max}
            />
            </div>
        </div>
    )
}

export default UserComponent