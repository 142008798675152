import React, { useEffect } from "react";
import useState from "react-usestateref";
import { useNavigate, useParams } from "react-router-dom";
import UserService from "../../../../services/UserService";
import { MainComponent } from "../main";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import LocationComponent from "../general/order-sections/location.component";
import ProtocolComponent from "../general/order-sections/protocols.compoennt";
import UserComponent from "../general/order-sections/users.component";
import PeriodComponent from "../general/order-sections/period.component";
import SummaryComponent from "../general/order-sections/summary.component";
import { SectionTitleComponent } from "../general";
import { Checkbox, FormControlLabel } from "@mui/material";
import ExtraFeaturesComponent from "../general/order-sections/extra-features.compoennt";

const swl = withReactContent(Swal);

const OrderNewService = () => {
  const { plan_id } = useParams();
  const navigate = useNavigate();
  const [waiting, setWaiting] = useState(false);
  const [loading, setLoading] = useState(true);
  const [data, setData, setDataRef] = useState(null);
  const [plan, setPlan, setPlanRef] = useState(null);
  const [country, setCountry, setCountryRef] = useState(null);
  const [protocols, setProtocols, setProtocolsRef] = useState([]);
  const [protocolsString, setProtocolsString] = useState([]);
  const [onlineUsers, setOnlineUsers, onlineUserRef] = useState(0);
  const [registeredUsers, setRegisteredUsers, registeredUsersRef] = useState(0);
  const [periods, setPeriods, setPeriodsRef] = useState([]);
  const [period, setPeriod, setPeriodRef] = useState(0);
  const [shop, setShop, setShopRef] = useState(true);
  const [bot, setBot, setBotRef] = useState(true);
  const [basePrices, setBasePrices, setBasePricesRef] = useState([]);
  const [fail, setFail] = useState(null);
  const [periodCategory, setPeriodCategory, setPeriodCategoryRef] = useState(0);

  //prices
  const [price, setPrice] = useState(0);
  const [periodPrice, setPeriodPrice] = useState(0);
  const [countryPrice, setCountryPrice] = useState(0);
  const [shopPrice, setShopPrice] = useState(0);
  const [botPrice, setBotPrice] = useState(0);
  const [protocolPrice, setProtocolPrice] = useState(0);
  const [onlineUsersPrice, setOnlineUsersPrice] = useState(0);
  const [registeredUsersPrice, setRegisteredUsersPrice] = useState(0);
  const [onlineUsersError, setOnlineUsersError] = useState('');

  useEffect(() => {
    const getPlanInfo = async () => {
      const [response] = await Promise.all([UserService.getPlanInfo(plan_id)]);

      setPlan(response.plan_info);
      setData(response);
      setPeriods(response.periods);

      //predefined plan info
      setProtocolsString(response.protocols_string);
      setPeriod(response.plan_info.period);
      setBasePrices(response.base_prices);
      setProtocols(response.plan_info.features.protocols);
      setCountry(response.current_country);
      setShop(response.plan_info.features?.shop);
      setBot(response.plan_info.features?.bot);
      setOnlineUsers(response.plan_info.features.online_users.min);
      setRegisteredUsers(response.plan_info.features.registered_users.min);
      setPrice(response.plan_info.price);
      setPeriodCategory(response.current_period_category);

      setLoading(false);
      calculatePrice();
    };

    getPlanInfo();
  }, []);

  const handleOrder = () => {
    setWaiting(true);
    setFail(null);
    setOnlineUsersError('');

    if (onlineUsers > registeredUsers) {
      setOnlineUsersError('Your online users cannot be greater than registered users.');
      return false;
    }

    const data = {
      protocols: protocols,
      online_users: onlineUsers,
      registered_users: registeredUsers,
      period: period.id,
    };

    //add country to data if plan has country
    if (typeof plan.features.location !== "undefined") {
      data.location = country.unique_id;
    }

    //add shop to data if plan has shop
    if (typeof plan.features.shop !== "undefined") {
      data.shop = shop;
    }

    //add bot to data if plan has bot
    if (typeof plan.features.bot !== "undefined") {
      data.bot = bot;
    }

    UserService.sendRequest(plan_id, data)
      .then(
        (response) => {
          if (response?.status == "success") {
            swl.fire({
              title: <strong>Your order has been saved!</strong>,
              icon: "success",
              willClose: () => {
                navigate("/orders");
                return false;
              },
            });
          }
        },
        (error) => {
          const response = error.response;
          setFail(response.data);
        }
      )
      .finally(() => {
        setWaiting(false);
      });
  };

  async function calculatePrice() {
    //location price
    let price = caclculateLocationPrice();

    //shop price
    price += calculateShopPrice();

    //bot price
    price += calculateBotPrice();

    //protocols price
    price += calculateProtocolsPrice();

    //online users price
    price += calculateOnlineUsersPrice();
    
    //registered users price
    price += calculateRegisteredUsersPrice();
    
    //period * total price
    price *= getSelectedPeriodCount();

    price = parseFloat(price.toFixed(4));

    setPrice(price);
  }

  function caclculateLocationPrice() {
    let price =
      setCountryRef.current !== null
        ? parseFloat(setCountryRef.current.data?.price)
        : 0;
    setCountryPrice(price);

    return price;
  }

  function calculateShopPrice() {
    let price = 0;

    if (setShopRef.current) {
      let shop_price = setBasePricesRef.current.shop;
      price = shop_price.data.price;
    }

    setShopPrice(price);
    return parseFloat(price);
  }

  function calculateBotPrice() {
    let price = 0;

    if (setBotRef.current) {
      let bot_price = setBasePricesRef.current.bot;
      price = bot_price.data.price;
    }

    setBotPrice(price);
    return parseFloat(price);
  }

  function getSelectedPeriodCount() {
    /*let price = 0;
    if (typeof basePrices === "undefined") return price;

    const selected = setPeriodsRef.current.find((item) => {
      return item.id == setPeriodRef.current.id;
    });

    const category = setDataRef.current.period_categories.find((item) => {
      return item.unique_id == selected?.parent_unique_id;
    });

    let period_base_price = setBasePricesRef.current.periods.find((item) => {
      return item.data.mode == category?.data?.var;
    });

    price = period_base_price?.data?.base_price * selected?.data?.count;

    setPeriodPrice(price);
    return parseFloat(price);*/

    const selected = setPeriodsRef.current.find((item) => {
      return item.id == setPeriodRef.current.id;
    });

    return selected?.data?.count;
  }

  function calculateRegisteredUsersPrice() {
    let price = setPlanRef.current.features.registered_users_price.base_price;
    if (
      parseInt(registeredUsersRef.current) >
      setPlanRef.current.features.registered_users_price.per_each
    ) {
      // console.log(parseInt(registeredUsers));
      let count =
        parseInt(registeredUsersRef.current) -
        setPlanRef.current.features.registered_users_price.per_each;
      price +=
        Math.ceil(count / setPlanRef.current.features.registered_users_price.per_each) *
        setPlanRef.current.features.registered_users_price.price_per_each;
    }
    setRegisteredUsersPrice(price);
    return parseFloat(price);
  }

  function calculateOnlineUsersPrice() {
    // let price = setDataRef.current.online_users?.data?.base_price;
    // if (
    //   parseInt(onlineUserRef.current) >
    //   setDataRef.current.online_users?.data?.per_each
    // ) {
    //   let count =
    //     parseInt(onlineUserRef.current) -
    //     setDataRef.current.online_users?.data?.per_each;
    //   price +=
    //     Math.ceil(count / setDataRef.current.online_users?.data?.per_each) *
    //     setDataRef.current.online_users?.data?.price_per_each;
    // }

    //changed by Hamze by sending the new formula => 1402-10-22
    //this is the new formula => fi := Round( (minf -maxf) / (maxc -minc) * (cnt -minc) + maxf );
    // let price = Math.round(((((setPlanRef.current.features.online_users_price.max * 100) / (onlineUserRef.current + 100)) + setPlanRef.current.features.online_users_price.min)) * onlineUserRef.current);

    let minf = setPlanRef.current.features.online_users_price.min;
    let maxf = setPlanRef.current.features.online_users_price.max;
    let minc = setPlanRef.current.features.online_users.min;
    let maxc = setPlanRef.current.features.online_users.max;
    let cnt = onlineUserRef.current;

    let price = parseFloat((((minf - maxf) / (maxc - minc)) * (cnt - minc) + maxf).toFixed(4));

    setOnlineUsersPrice(price);
    return price;
  }

  function calculateProtocolsPrice() {
    let res = setDataRef.current.protocols?.filter((item) =>
      setProtocolsRef.current?.includes(parseInt(item.unique_id))
    );
    let price = 0;
    if (res?.length)
      price = res
        .map((item) => parseFloat(item.data.price))
        ?.reduce((prev, next) => parseFloat(prev) + parseFloat(next));
    setProtocolPrice(price);
    return parseFloat(price);
  }

  async function handlePeriodCategory(unique_id) {
    setPeriodCategory(unique_id);
    await UserService.getPeriods(unique_id).then((response) => {
      setPeriods(response.periods);
      setPeriod(response.periods[0]);
    });

    calculatePrice();
  }

  function handlePeriod(period) {
    const selected = setPeriodsRef.current.find((item) => {
      return item.id == period;
    });

    setPeriod(selected);
    calculatePrice();
  }

  function handleCountry(country) {
    const selected = data.countries.find((item) => {
      return item.unique_id == country;
    });
    setCountry(selected);
    calculatePrice();
  }

  function handleShop(e) {
    setShop(e.checked);
    calculatePrice();
  }

  function handleBot(e) {
    setBot(e.checked);
    calculatePrice();
  }

  function handleProtocols(protocol) {
    const newProtocols = protocols?.includes(parseInt(protocol.value))
      ? protocols?.filter((p) => p !== parseInt(protocol.value))
      : [...(protocols ?? []), parseInt(protocol.value)];
    setProtocols(newProtocols);

    const newProtocolsString = protocolsString?.includes(protocol.name)
      ? protocolsString?.filter((p) => p !== protocol.name)
      : [...(protocolsString ?? []), protocol.name];

    setProtocolsString(newProtocolsString);
    calculatePrice();
  }

  function handleOnlineUser(event, count) {
    setOnlineUsers(count);
    calculatePrice();
  }

  function handleRegisteredUser(event, count) {
    setRegisteredUsers(count);
    calculatePrice();
  }

  if (loading) return "loading ...";

  return (
    <section className="hero-replace">
      <div className="container">
        <SectionTitleComponent>New Order ({plan.name})</SectionTitleComponent>
        <MainComponent>
          <div className="row">
            <div
              className="col-lg-8 mt-5 mt-lg-0"
              data-aos="fade-left"
              data-aos-delay="200"
            >
              <form method="post" role="form" className="react-form">
                {typeof plan.features.location !== "undefined" ? (
                  <LocationComponent
                    countries={data.countries}
                    currentCountry={country}
                    handleCountry={handleCountry}
                  />
                ) : (
                  ""
                )}

                {typeof plan.features.shop !== "undefined" ||
                typeof plan.features.bot !== "undefined" ? (
                  <ExtraFeaturesComponent
                    shop={shop}
                    plan={plan}
                    bot={bot}
                    handleBot={handleBot}
                    handleShop={handleShop}
                  />
                ) : (
                  ""
                )}

                <ProtocolComponent
                  protocols={data.protocols}
                  currentProtocols={protocols}
                  handleProtocols={handleProtocols}
                />

                <UserComponent
                  step={plan.features.online_users.step}
                  min={plan.features.online_users.min}
                  max={plan.features.online_users.max}
                  handleUser={handleOnlineUser}
                  label="Online user count"
                />

                {
                  onlineUsersError == '' ? '' : <div className="row"><div className="col-12 alert alert-danger">{onlineUsersError}</div></div>
                }

                <UserComponent
                  step={plan.features.registered_users.step}
                  min={plan.features.registered_users.min}
                  max={plan.features.registered_users.max}
                  handleUser={handleRegisteredUser}
                  label="Register user count"
                />

                <PeriodComponent
                  periodCategories={data.period_categories}
                  currentPeriodCategory={periodCategory}
                  periods={periods}
                  handlePeriodCategory={handlePeriodCategory}
                  handlePeriod={handlePeriod}
                />
              </form>
            </div>

            <SummaryComponent
              plan={plan}
              country={country}
              countryPrice={countryPrice}
              protocolsString={protocolsString}
              protocolPrice={protocolPrice}
              onlineUsers={onlineUsers}
              onlineUsersPrice={onlineUsersPrice}
              registeredUsers={registeredUsers}
              registeredUsersPrice={registeredUsersPrice}
              shop={shop}
              shopPrice={shopPrice}
              bot={bot}
              botPrice={botPrice}
              waiting={waiting}
              fail={fail}
              price={price}
              handleOrder={handleOrder}
            />
          </div>
        </MainComponent>
      </div>
    </section>
  );
};

export default OrderNewService;
