import { useState } from "react";

const NavbarSubmenu = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <li className="dropdown">
      <a href="#" onClick={toggleNavbar}>
        <span>{title}</span>
        <i className="bi bi-chevron-down"></i>
      </a>
      <ul className={isOpen ? 'dropdown-active' : ''}>{children}</ul>
    </li>
  );
};

export default NavbarSubmenu;
