import React, { useState, useEffect } from "react";
import AuthService from "../../../../services/AuthService";
import { MainComponent } from "../main";
import { Loading, SectionTitleComponent } from "../general";
import { useNavigate } from "react-router-dom";
import { ShowErrors } from "../../../../helpers/extractErrors";
import  secureLocalStorage  from  "react-secure-storage";

const Register = () => {
  const navigate = useNavigate();
  const [waiting, setWaiting] = useState(false);
  const [fail, setFail] = useState(null);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  // const [mobile, setMobile] = useState("");
  // const [national_code, setNationalCode] = useState("");
  const [password, setPassword] = useState("");
  const [repeat_password, setRepeatPassword] = useState("");

  //   useEffect(() => {}, [waiting, fail]);

  const handleSubmit = (event) => {
    event.preventDefault();

    setWaiting(true);
    setFail(null);

    const data = {
      name,
      email,
      // mobile,
      // national_code,
      password,
      repeat_password,
    };

    AuthService.register(data)
      .then(
        (response) => {
          if (response.status) {
            if (response.need_to_be_verified) {
              secureLocalStorage.setItem(
                "need_to_be_verified",
                response.need_to_be_verified
              );

              navigate("/verification", {
                state: {
                  message: "You need to confirm your email first.",
                },
              });
              return false;
            }
            navigate("/dashboard");
          }
        },
        (error) => {
          const response = error.response;
          setFail(response?.data);
        }
      )
      .finally(() => {
        setWaiting(false);
      });
  };

  return (
    <div className="hero-replace">
      <div className="container">
        <SectionTitleComponent>Register</SectionTitleComponent>

        <div className="row">
          <MainComponent>
            <div
              className="col-lg-8 mt-5 mt-lg-0"
              data-aos="fade-left"
              data-aos-delay="200"
            >
              <form method="post" role="form" className="react-form" onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6 form-group">
                    <input
                      type="text"
                      name="name"
                      className="form-control"
                      id="name"
                      placeholder="Username"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                  <div className="col-md-6 form-group mt-3 mt-md-0">
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      id="email"
                      placeholder="Email"
                      required
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                </div>
                {/* <div className="row mt-3">
                  <div className="col-md-6 form-group">
                    <input
                      type="text"
                      name="mobile"
                      className="form-control"
                      id="mobile"
                      placeholder="Mobile"
                      value={mobile}
                      onChange={(e) => setMobile(e.target.value)}
                    />
                  </div>
                  <div className="col-md-6 form-group mt-3 mt-md-0">
                    <input
                      type="text"
                      className="form-control"
                      name="national_code"
                      id="national_code"
                      placeholder="National code"
                      value={national_code}
                      onChange={(e) => setNationalCode(e.target.value)}
                    />
                  </div>
                </div> */}
                <div className="row mt-3">
                  <div className="col-md-6 form-group">
                    <input
                      type="password"
                      name="password"
                      className="form-control"
                      id="password"
                      placeholder="Password"
                      required
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                  <div className="col-md-6 form-group mt-3 mt-md-0">
                    <input
                      type="password"
                      className="form-control"
                      name="repeat_password"
                      id="repeat_password"
                      placeholder="Repeat password"
                      required
                      value={repeat_password}
                      onChange={(e) => setRepeatPassword(e.target.value)}
                    />
                  </div>
                </div>
                <div className="mt-3">
                  <Loading waiting={waiting}/>
                  <ShowErrors fail={fail} />
                </div>
                <div className="text-right mt-3">
                  <button className="btn btn-primary" type="submit">
                    Register
                  </button>
                </div>
              </form>
            </div>
          </MainComponent>
        </div>
      </div>
    </div>
  );
};

export default Register;
