import { Link } from "react-router-dom";
import { SectionTitleComponent } from "../../general";

const NotFound = () => {
    return (
        <div className="hero-replace mb-5">
            <div className="container">
                <SectionTitleComponent>Oops, you seem lost!</SectionTitleComponent>
                <Link to="/">Home page</Link>
            </div>
        </div>
    );
}

export default NotFound;