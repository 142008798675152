import { MainComponent } from "../main";
import { Loading, SectionTitleComponent } from "../general";
import AuthService from "../../../../services/AuthService";
import { useState } from "react";
import { ShowErrors } from "../../../../helpers/extractErrors";

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
const swl = withReactContent(Swal)

const Forget = () => {
  const [waiting, setWaiting] = useState(false);
  const [fail, setFail] = useState(null);
  const [email, setEmail] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();

    setWaiting(true);
    setFail(null);

    let data = {
      email,
    };

    AuthService.forget(data)
      .then(
        (response) => {
          if (response?.status == "success") {
            swl.fire({
              title: <strong>The password reset link was sent to your email successfully!</strong>,
              icon: 'success'
            });
          } else {
            swl.fire({
              title: <strong>Unable to send the password reset link!</strong>,
              icon: 'error'
            });
          }
        },
        (error) => {
          const response = error.response;
          setFail(response.data);
        }
      )
      .finally(() => {
        setWaiting(false);
      });
  };

  return (
    <div className="hero-replace">
      <div className="container">
        <SectionTitleComponent>Forget Password</SectionTitleComponent>

        <div className="row">
          <MainComponent>
            <div
              className="col-lg-8 mt-5 mt-lg-0"
              data-aos="fade-left"
              data-aos-delay="200"
            >
              <form
                method="post"
                role="form"
                className="react-form"
                onSubmit={handleSubmit}
              >
                <div className="row">
                  <div className="col-md-6 form-group">
                    <input
                      type="text"
                      name="email"
                      className="form-control"
                      id="email"
                      placeholder="Email"
                      required
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                </div>
                <div className="mt-3">
                  <Loading waiting={waiting}/>
                  <ShowErrors fail={fail} />
                </div>

                <div className="text-right mt-3">
                  <button className="btn btn-primary" type="submit">
                    Send
                  </button>
                </div>
              </form>
            </div>
          </MainComponent>
        </div>
      </div>
    </div>
  );
};

export default Forget;
