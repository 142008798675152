import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Plans as PlansComponent,
  SectionTitleComponent,
} from "../general/index";

const Plans = () => {
  const { slug: service } = useParams();

  return (
    <section id="pricing" className="pricing hero-replace">
      <div className="container">
        {/* <SectionTitleComponent>پلن های سرویس {service}</SectionTitleComponent> */}
        <PlansComponent service='all' />
      </div>
    </section>
  );
};

export default Plans;
