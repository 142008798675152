import { HeaderComponent } from "./header";
import { FooterComponent } from "./footer";

const HostingTemplate = (props) => {
  return (
    <>
      <HeaderComponent />
      {props.children}
      <FooterComponent />
    </>
  );
};

export { HostingTemplate };
