import React, { useState } from "react";
import UserService from "../../../../services/UserService";
import { MainComponent } from "../main";
import { Loading, SectionTitleComponent } from "../general";
import { useNavigate } from "react-router-dom";
import { ShowErrors } from "../../../../helpers/extractErrors";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Option } from "../../../../helpers/helperFunctions";
import { useEffect } from "react";

const swl = withReactContent(Swal);

const CreateTicket = () => {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [waiting, setWaiting] = useState(false);
  const [fail, setFail] = useState(null);
  const [title, setTitle] = useState("");
  const [priority, setPriority] = useState("low");
  const [priorities, setPriorities] = useState();
  const [message, setMessage] = useState("");
  const [attachment, setAttachment] = useState("");

  
  useEffect(() => {
    const getPriorities = async () => {
      const [response] = await Promise.all([
        UserService.getTicketPriorities(),
      ]);
  
      setPriorities(response.priorities);
      setLoading(false);
    };

    getPriorities();
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();

    setWaiting(true);
    setFail(null);

    let data = new FormData();
    data.append("title", title);
    data.append("priority", priority);
    data.append("message", message);
    data.append("attachment", attachment);

    UserService.saveTicket(data)
      .then(
        (response) => {
          if (response.status) {
            swl.fire({
              title: <strong>Your ticket has been sent!</strong>,
              icon: "success",
              willClose: () => {
                navigate("/tickets");
                return false;
              },
            });
          }
        },
        (error) => {
          const response = error.response;
          setFail(response?.data);
        }
      )
      .finally(() => {
        setWaiting(false);
      });
  };

  if (loading) return "loading ...";

  return (
    <div className="hero-replace">
      <div className="container">
        <SectionTitleComponent>Add new ticket</SectionTitleComponent>

        <div className="row">
          <MainComponent>
            <div
              className="col-lg-8 mt-5 mt-lg-0"
              data-aos="fade-left"
              data-aos-delay="200"
            >
              <form
                method="post"
                role="form"
                className="react-form"
                onSubmit={handleSubmit}
              >
                <div className="row">
                  <div className="col-md-4 form-group">
                    <select
                      name="country"
                      className="form-control"
                      id="country"
                      required
                      value={priority}
                      onChange={(e) => setPriority(e.target.value)}
                    >
                      {priorities.map((item, index) => {
                        return (
                          <Option
                            key={index}
                            value={item.key}
                            label={item.value}
                          />
                        );
                      })}
                    </select>
                  </div>
                  <div className="col-md-4 form-group">
                    <input
                      type="text"
                      name="title"
                      className="form-control"
                      id="title"
                      placeholder="subject"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                    />
                  </div>
                  <div className="col-md-4 form-group">
                    <input
                      type="file"
                      name="attachment"
                      id="attachment"
                      onChange={(e) => setAttachment(e.target.files[0])}
                    />
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-md-12 form-group">
                    <textarea
                      name="message"
                      className="form-control"
                      id="message"
                      placeholder="body"
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                    ></textarea>
                  </div>
                </div>
                <div className="mt-3">
                  <Loading waiting={waiting} />
                  <ShowErrors fail={fail} />
                </div>
                <div className="text-right mt-3">
                  <button className="btn btn-primary" type="submit">
                    Send
                  </button>
                </div>
              </form>
            </div>
          </MainComponent>
        </div>
      </div>
    </div>
  );
};

export default CreateTicket;
