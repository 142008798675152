import {
    Plans as PlansComponent,
  } from "../../../general/index";

const PricingComponent = () => {
    return (
        <section id="pricing" className="pricing">
            <div className="container">

                <div className="section-title" data-aos="fade-up">
                    <p>CHECK OUR PRICING</p>
                </div>

                <PlansComponent service="all"/>

            </div>
        </section>
    )
}

export default PricingComponent;