const RequestStatusComponent = ({status, statusName}) => {
    let className = 'badge bg-warning';
    switch(status) {
      case 'pending':
        className = 'badge bg-warning';
        break;
      case 'approved':
        className = 'badge bg-success';
        break;
      case 'rejected':
        className = 'badge bg-danger';
        break;
      case 'corrected':
        className = 'badge bg-primary';
        break;
    }

    return <span className={className}>{statusName}</span>
  }

  export default RequestStatusComponent;