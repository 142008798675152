import axios from "../utils/axios";
import  secureLocalStorage  from  "react-secure-storage";
import moment from "moment"

const API_URL = "/auth";

class AuthService {

  getExpireTime() {
    return moment().add(process.env.REACT_APP_REFRESH_TOKEN_TIME, 'm').format();
  }

  verify(data) {
    const payload = data;
    return axios.post(API_URL + "/verification", payload).then((response) => {
      if (response.data.status == 'success') {
        secureLocalStorage.removeItem("need_to_be_verified");
      }

      return response.data;
    });
  }

  login(data) {
    const payload = data;
    return axios.post(API_URL + "/login", payload).then((response) => {
      if (response.data.user) {
        secureLocalStorage.setItem("user", JSON.stringify(response.data.user));

        secureLocalStorage.setItem(
          "jwt",
          response?.headers?.['authorization']
        );

        //expire time 1 hour
        secureLocalStorage.setItem(
          "exp",
          this.getExpireTime()
        );
      }

      return response.data;
    });
  }

  forget(data) {
    const payload = data;
    return axios.post(API_URL + "/forget", payload).then((response) => {
      return response.data;
    });
  }

  resetPassword(data) {
    const payload = data;
    return axios.post(API_URL + "/reset-password", payload).then((response) => {
      return response.data;
    });
  }

  profile(data) {
    const payload = data;
    return axios.post(API_URL + "/profile", payload).then((response) => {
      return response.data;
    });
  }

  adminLogin(data) {
    const payload = data;
    return axios.post(API_URL + "/admin/login", payload).then((response) => {
      if (response.data.admin) {
        secureLocalStorage.setItem("admin", JSON.stringify(response.data.admin));

        secureLocalStorage.setItem(
          "admin-jwt",
          response?.headers?.['authorization']
        );
      }

      return response.data;
    });
  }

  logout() {
    secureLocalStorage.removeItem("services");
    secureLocalStorage.removeItem("user");
    secureLocalStorage.removeItem("exp");
    secureLocalStorage.removeItem("jwt");
    secureLocalStorage.removeItem("admin-jwt");
    secureLocalStorage.removeItem("need_to_be_verified");
  }

  register(data) {
    const payload = data;
    return axios.post(API_URL + "/register", payload).then((response) => {
      if (response.data.user) {
        secureLocalStorage.setItem("user", JSON.stringify(response.data.user));
        
        secureLocalStorage.setItem(
          "jwt",
          response?.headers?.['authorization']
        );

        //expire time 1 hour
        secureLocalStorage.setItem(
          "exp",
          this.getExpireTime()
        );
      }

      return response.data;
    });
  }

  getCurrentUser(mode = "user") {
    if (mode == "user")
      return JSON.parse(secureLocalStorage.getItem("user"));
    return JSON.parse(secureLocalStorage.getItem("admin"));
  }

  getJwtToken() {
    return secureLocalStorage.getItem("jwt");
  }

  getAdminJwtToken() {
    return secureLocalStorage.getItem("admin-jwt");
  }

  isVerified() {
    if (secureLocalStorage.getItem("need_to_be_verified") === null)
      return true;
    return false;
  }

  authHeader() {
    const jwt = this.getJwtToken();

    if (jwt) {
      return { Authorization: "Bearer " + jwt };
    } else {
      return {};
    }
  }
}

export default new AuthService();
