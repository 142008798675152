import useState from "react-usestateref";
import { Link, NavLink } from "react-router-dom";
import NavbarSubmenu from "../../general/navbar-submenu.component";

const GuestNavbarComponent = () => {
    const [homeUrl, setHomeUrl, setHomeUrlRef] = useState(window.location.origin);
    const [currentUrl, setCurrentUrl, setCurrentUrlRef] = useState(window.location.origin);

    if (setCurrentUrlRef.current.split("#")[0].endsWith("/")) {
        setHomeUrl("");
    }

    return (
        <ul>
            <li><a className="nav-link scrollto active" href={`${setHomeUrlRef.current}#hero`}>Home</a></li>
            <li><a className="nav-link scrollto" href={`${setHomeUrlRef.current}#about`}>About Us</a></li>
            <li><a className="nav-link scrollto" href={`${setHomeUrlRef.current}#features`}>Features</a></li>
            <li><a className="nav-link scrollto" href={`${setHomeUrlRef.current}#gallery`}>Gallery</a></li>
            <li><a className="nav-link scrollto" href={`${setHomeUrlRef.current}#pricing`}>Pricing</a></li>
            <NavbarSubmenu title="User Area">
                <li><NavLink to="/signin">Login</NavLink></li>
                <li><NavLink to="/signup">Register</NavLink></li>
            </NavbarSubmenu>

            {/* <li className="dropdown">
                <a href="#">
                    <span>User Area</span>
                    <i className="bi bi-chevron-down"></i>
                </a>
                <ul>
                    <li><NavLink to="/signin">Login</NavLink></li>
                    <li><NavLink to="/signup">Register</NavLink></li>
                </ul>
            </li> */}
        </ul>
    );
}

export default GuestNavbarComponent