

const MainComponent = (props) => {
    return (
        <main id="main">
            {
                props.children
            }
        </main>
    );  
}

export default MainComponent;