import { useEffect, useState } from "react";
import {Plan} from "./index"
import UserService from "../../../../services/UserService";

const Plans = ({service}) => {
    const [loading, setLoading] = useState(false);
    const [plans, setPlans] = useState(null);

    useEffect(() => {
        setLoading(true);
        const getPlans = async () => {
            UserService.getPlans(service).then((data) => {
                setPlans(data);
            }).finally(setLoading(false));
        }

        getPlans();
    }, [service]);

    return (
        <>
            <div className="row aos-init aos-animate" data-aos="fade-left">
            {
                loading ?
                "Loading..." :
                plans?.map((plan) => {
                    return <Plan key={plan.id} name={plan.name} period_id={plan.period_id} period = {plan.period_name} price={plan.price} country={plan.country} protocols={plan.protocols} features={plan.features} uuid={plan.uuid}></Plan>
                })
            }
            {plans && <Plan name="Licence Sale" period_id="0" period = "0" price="" country="" protocols={[]} features={[]} uuid="without-id"></Plan>}
            </div>
        </>
    );
}

export default Plans;