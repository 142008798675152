export const ShowErrors = (props) => {
  if (props.fail === null) return "";

  //we have errors
  if (typeof props.fail.errors !== "undefined")
    return (
      <div className="error-message">
        {Object.entries(props.fail.errors).map(([key, value]) => {
          return <div className="row">{value}</div>;
        })}
      </div>
    );

  if (typeof props.fail.message !== undefined)
    return <div className="error-message">{props.fail.message}</div>;
};
