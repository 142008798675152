const AboutComponent = () => {
    return (
        <section id="about" className="about">
            <div className="container-fluid">

                <div className="row">
                    <div className="col-xl-5 col-lg-6 video-box d-flex justify-content-center align-items-stretch" data-aos="fade-right">
                        <a href="https://www.youtube.com/watch?v=StpBR2W8G5A" className="glightbox play-btn mb-4" style={{visibility: "hidden"}}></a>
                    </div>

                    <div className="col-xl-7 col-lg-6 icon-boxes d-flex flex-column align-items-stretch justify-content-center py-5 px-lg-5" data-aos="fade-left">
                        <h3>From now on, calculate the consumption volume of your customers and carefully monitor their entry and exit times with Cloudius</h3>
                        <p>Why use {process.env.REACT_APP_APP_NAME}?</p>

                        <div className="icon-box" data-aos="zoom-in" data-aos-delay="200">
                            <div className="icon"><i className="bx bx-fingerprint"></i></div>
                            <h4 className="title"><a href="">Full API</a></h4>
                            <p className="description">Cloudius is built as a complete and multi-layered API. For easy connection of all platforms. Through API, you can see reports of your customers outside the management panel. Also, through this API, you can make your users online or offline and even send their consumption volume to Radius server for calculation.</p>
                        </div>

                        <div className="icon-box" data-aos="zoom-in" data-aos-delay="100">
                            <div className="icon"><i className="bx bx-gift"></i></div>
                            <h4 className="title"><a href="">Full protocol</a></h4>
                            <p className="description">We support all RADIUS-based protocols such as: PAP-CHAP-MSChapv1-MSChapv2-EAP Full</p>
                        </div>

                        <div className="icon-box" data-aos="zoom-in" data-aos-delay="300">
                            <div className="icon"><i className="bx bx-atom"></i></div>
                            <h4 className="title"><a href="">Full service</a></h4>
                            <p className="description">We support all internet services, such as VPN services, including Cisco - OpenVPN - L2tp - Ikev2 - SSH - SOCKS5 and V2ray , as well as Trad servers and Game servers, also ping reduction systems.</p>
                        </div>

                    </div>
                </div>

            </div>
        </section>
    )
}

export default AboutComponent;