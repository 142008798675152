import { MainComponent } from "../main";
import { Loading, SectionTitleComponent } from "../general";
import { Link, useNavigate, useLocation } from "react-router-dom";
import AuthService from "../../../../services/AuthService";
import { useState } from "react";
import { ShowErrors } from "../../../../helpers/extractErrors";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const swl = withReactContent(Swal);

const ResetPassword = () => {
  const params = new URLSearchParams(window.location.search)
  let token = params.get('token')

  const navigate = useNavigate();
  const [waiting, setWaiting] = useState(false);
  const [fail, setFail] = useState(null);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();

    setWaiting(true);
    setFail(null);

    let data = {
      email,
      password,
      password_confirmation: passwordConfirmation,
      token,
    };

    AuthService.resetPassword(data)
      .then(
        (response) => {
          if (response.status) {
            navigate("/signin");
          }
        },
        (error) => {
          const response = error.response;
          setFail(response.data);
        }
      )
      .finally(() => {
        setWaiting(false);
      });
  };

  return (
    <div className="hero-replace">
      <div className="container">
        <SectionTitleComponent>Reset Password</SectionTitleComponent>

        <div className="row">
          <MainComponent>
            <div
              className="col-lg-8 mt-5 mt-lg-0"
              data-aos="fade-left"
              data-aos-delay="200"
            >
              <form
                method="post"
                role="form"
                className="react-form"
                onSubmit={handleSubmit}
              >
                <div className="row">
                  <div className="col-md-6 form-group">
                    <input
                      type="text"
                      name="email"
                      className="form-control"
                      id="email"
                      placeholder="Email"
                      required
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div className="col-md-6 form-group mt-3 mt-md-0">
                    <input
                      type="password"
                      className="form-control"
                      name="password"
                      id="password"
                      placeholder="Password"
                      required
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-md-6 form-group mt-3 mt-md-0">
                    <input
                      type="password"
                      className="form-control"
                      name="passwordConfirmation"
                      id="passwordConfirmation"
                      placeholder="Password Confirmation"
                      required
                      value={passwordConfirmation}
                      onChange={(e) => setPasswordConfirmation(e.target.value)}
                    />
                  </div>
                </div>
                <div className="mt-3">
                  <Loading waiting={waiting} />
                  <ShowErrors fail={fail} />
                </div>

                <div className="text-right mt-3">
                  <button className="btn btn-primary" type="submit">
                    Change Password
                  </button>
                </div>
              </form>
            </div>
          </MainComponent>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
