import { Option } from "../../../../../helpers/helperFunctions";

const LocationComponent = ({countries, currentCountry, handleCountry}) => {
    return (
        <div className="row g-3 align-items-center">
            <div className="col-auto">
            <label className="col-form-label">Location</label>
            </div>
            <div className="col-md-4">
            <select
                name="country"
                className="form-control"
                id="country"
                required
                onChange={(e) => handleCountry(e.target.value)}
            >
                {countries.map((item) => {
                return (
                    <Option
                    key={item.id}
                    value={item.unique_id}
                    label={item.name}
                    selected={
                        currentCountry.unique_id == item.unique_id ? true : false
                    }
                    />
                );
                })}
            </select>
            </div>
        </div>
    )
}

export default LocationComponent