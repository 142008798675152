import { AuthContext } from "../../../context/authContext";
import {GuestNavbarComponent, UserNavbarComponent} from "./navbar"
import { useContext, useState } from "react";
const HeaderComponent = () => {
    const authContext = useContext(AuthContext);
    const [isOpen, setIsOpen] = useState(false);

    return (
        <header id="header" className="fixed-top d-flex align-items-center">
            <div className="container d-flex align-items-center justify-content-between">

                <div className="logo">
                    <h1><a href="#"><span>{process.env.REACT_APP_APP_NAME}</span></a></h1>
                    <a href="#"><img src="assets/img/logo.png" alt="" className="img-fluid" /></a>
                </div>

                <nav id="navbar" className={isOpen ? "navbar-mobile" : "navbar"}>
                    {
                        authContext.loggedIn ? 
                        <UserNavbarComponent/> :
                        <GuestNavbarComponent/>
                    }
                    <i onClick={() => setIsOpen(!isOpen)} className={ isOpen ? "bi mobile-nav-toggle bi-x" : "bi mobile-nav-toggle bi-list"}></i>
                </nav>

            </div>
        </header>
    );
}

export default HeaderComponent;