import { MainComponent } from "../main";
import { Loading, SectionTitleComponent } from "../general";
import { Link, useNavigate } from "react-router-dom";
import AuthService from "../../../../services/AuthService";
import { validateEmail } from "../../../../utils/validator";
import { useContext, useEffect, useState } from "react";
import { ShowErrors } from "../../../../helpers/extractErrors";
import { AuthContext } from "../../../context/authContext";
import  secureLocalStorage  from  "react-secure-storage";


const Login = () => {
  const authContext = useContext(AuthContext);
  const navigate = useNavigate();
  const [waiting, setWaiting] = useState(false);
  const [needToBeVerified, setNeedToBeVerified] = useState(false);
  const [fail, setFail] = useState(null);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();

    setWaiting(true);
    setNeedToBeVerified(false);
    setFail(null);

    let data = {
      name: username,
      password,
    };
    // if (validateEmail(email)) {
    //   data = {
    //     email: email,
    //     password,
    //   };
    // } else {
    //   data = {
    //     mobile: email,
    //     password,
    //   };
    // }

    AuthService.login(data)
      .then(
        (response) => {
          if (response.status) {
            if (response.need_to_be_verified) {
              secureLocalStorage.setItem(
                "need_to_be_verified",
                response.need_to_be_verified
              );

              navigate("/verification", {
                state: {
                  message: "You need to confirm your email first.",
                },
              });
              return false;
            }

            authContext.setLoggedIn(true);
            authContext.setUser(AuthService.getCurrentUser());

            navigate("/dashboard");
          }
        },
        (error) => {
          const response = error.response;
          setFail(response.data);
          if (response.status == 401) {
            setNeedToBeVerified(true);
          }
        }
      )
      .finally(() => {
        setWaiting(false);
      });
  };

  return (
    <div className="hero-replace">
      <div className="container">
        <SectionTitleComponent>Login</SectionTitleComponent>

        <div className="row">
          <MainComponent>
            <div
              className="col-lg-8 mt-5 mt-lg-0"
              data-aos="fade-left"
              data-aos-delay="200"
            >
              <form
                method="post"
                role="form"
                className="react-form"
                onSubmit={handleSubmit}
              >
                <div className="row">
                  <div className="col-md-6 form-group">
                    <input
                      type="text"
                      name="username"
                      className="form-control"
                      id="username"
                      placeholder="Username"
                      required
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                    />
                  </div>
                  <div className="col-md-6 form-group mt-3 mt-md-0">
                    <input
                      type="password"
                      className="form-control"
                      name="password"
                      id="password"
                      placeholder="Password"
                      required
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                </div>
                <div className="mt-3">
                  <Loading waiting={waiting}/>
                  <ShowErrors fail={fail} />
                </div>

                <div className="mt-3">
                  {needToBeVerified ? (
                    <Link to="/verification">Confirm your account</Link>
                  ) : (
                    ""
                  )}
                </div>

                <div className="text-right mt-3">
                  <button className="btn btn-primary" type="submit">
                    Login
                  </button>
                </div>
                <div className="text-right mt-3">
                  <Link style={{color: "#1a1d94"}} to="/forget">Forget your password?</Link>
                </div>
              </form>
            </div>
          </MainComponent>
        </div>
      </div>
    </div>
  );
};

export default Login;
