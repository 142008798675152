import { Link, NavLink } from "react-router-dom";
import { AuthContext } from "../../../../context/authContext";
import React, { useContext, useEffect, useState } from "react";
import UserService from "../../../../../services/UserService";
import { slug } from "../../../../../helpers/helperFunctions";
import NavbarSubmenu from "../../general/navbar-submenu.component";

class UserNavbarComponent extends React.Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);

    this.state = {
      data: null,
    };

    const getServices = async () => {
      this.setState({ data: await UserService.getServices() });
    };

    getServices();
  }

  render() {
    return (
      <ul>
        <li>
          <NavLink className="nav-link" to="/">
            Home
          </NavLink>
        </li>
        {/* <li><NavLink className="nav-link scrollto" to="/my-services">سرویس های من</NavLink></li> */}
        <li>
          <NavLink className="nav-link" to="/tickets">
            Ticket
          </NavLink>
        </li>
        <li>
          <NavLink className="nav-link" to="/orders">
            Orders
          </NavLink>
        </li>
        <li>
          <NavLink className="nav-link" to="/plans">
            New Order
          </NavLink>
        </li>
        {/* <li className="dropdown">
                    <a href="#">
                        <span>
                        سفارش سرویس جدید
                        </span>
                        <i className="bi bi-chevron-down"></i>
                    </a>
                    <ul>
                        {
                            this.state.data &&  this.state.data.map((item) => {
                                return <li key={item.id}><NavLink to={"/services/" + slug(item.name) + "/plans"}>{item.name}</NavLink></li>
                            })
                        }
                    </ul>
                </li> */}

        <NavbarSubmenu title={`Welcome(${this.context.user.name})`}>
          <li>
            <NavLink to="/profile">Profile</NavLink>
          </li>
          <li>
            <NavLink to="/logout">Logout</NavLink>
          </li>
        </NavbarSubmenu>

        {/* <li className="dropdown">
          <a href="#">
            <span>Welcome({this.context.user.name})</span>
            <i className="bi bi-chevron-down"></i>
          </a>
          <ul></ul>
        </li> */}
      </ul>
    );
  }
}

export default UserNavbarComponent;
