const CountsComponent = () => {
    return (
        <section id="counts" className="counts">
            <div className="container">

                <div className="row" data-aos="fade-up">

                    <div className="col-lg-3 col-md-6">
                        <div className="count-box">
                            <i className="bi bi-emoji-smile"></i>
                            <span data-purecounter-start="0" data-purecounter-end="153" data-purecounter-duration="1" className="purecounter">153</span>
                            <p>Active Admin</p>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6 mt-5 mt-md-0">
                        <div className="count-box">
                            <i className="bi bi-journal-richtext"></i>
                            <span data-purecounter-start="0" data-purecounter-end="35231" data-purecounter-duration="1" className="purecounter">35231</span>
                            <p>Active User</p>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6 mt-5 mt-lg-0">
                        <div className="count-box">
                            <i className="bi bi-headset"></i>
                            <span data-purecounter-start="0" data-purecounter-end="25" data-purecounter-duration="1" className="purecounter">25</span>
                            <p>Active Server</p>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6 mt-5 mt-lg-0">
                        <div className="count-box">
                            <i className="bi bi-people"></i>
                            <span data-purecounter-start="0" data-purecounter-end="20" data-purecounter-duration="1" className="purecounter">20</span>
                            <p>Active Service</p>
                        </div>
                    </div>

                </div>

            </div>
        </section>
    )
}

export default CountsComponent;