import axios from "axios";
import secureLocalStorage from "react-secure-storage";
import moment from "moment"

const instance = axios.create({
  timeout: process.env.REACT_APP_API_TIMEOUT,
  baseURL: process.env.REACT_APP_API_URL,
});

// Add a request interceptor
instance.interceptors.request.use(
  async (config) => {
    const token = secureLocalStorage.getItem("jwt") || secureLocalStorage.getItem("admin-jwt");
    if (token) {
      const exp = secureLocalStorage.getItem("exp");
      if (exp && moment(exp).isBefore()) {
        const headers = {
          'Content-Type': 'application/json',
          'Authorization': token
        }

        //if refresh time is reached
        await axios
          .post(process.env.REACT_APP_API_URL + "/auth/token/refresh", {},{ headers: headers })
          .then((response) => {
            if (response.data.user) {
              secureLocalStorage.setItem(
                "jwt",
                response?.headers?.["authorization"]
              );

              //expire time 1 hour
              secureLocalStorage.setItem(
                "exp",
                moment().add(process.env.REACT_APP_REFRESH_TOKEN_TIME, 'm').format()
              );
            }
          }).catch(error => {
            
          });
      }
    }

    config.headers["Authorization"] = token;
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

instance.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    if (error.response.status === 401 ) {
      //logout user
      secureLocalStorage.removeItem("user");
      secureLocalStorage.removeItem("services");
      secureLocalStorage.removeItem("exp");
      secureLocalStorage.removeItem("jwt");
      secureLocalStorage.removeItem("need_to_be_verified");

      window.location.href = window.location.protocol + '//' + window.location.host + '/signin';
    }
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error

    return Promise.reject(error);
    // return error;
  }
);

export default instance;
