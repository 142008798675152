import React, { useContext } from "react";
import { Route, Routes, Navigate } from "react-router";
import { AuthContext } from "../context/authContext";
import ConditionalRoute from "../../utils/conditionalRoute";
import { Outlet } from "react-router-dom";

//themes
import { HostingTemplate } from "../layouts/hosting";

//user pages
import {
  Login,
  Home,
  NotFound,
  Register,
  Verification,
  Dashboard,
  MyServices,
  Profile,
  Plans,
  OrderNewService,
  Logout,
  Orders,
  Order,
  Tickets,
  CreateTicket,
  ViewTicket
} from "../layouts/hosting/pages";
import Forget from "./hosting/pages/Forget";
import ResetPassword from "./hosting/pages/ResetPassword";

const UserLayout = () => {
  return (
    <HostingTemplate>
      <Outlet />
    </HostingTemplate>
  );
};

const AppRoutes = () => {
  const authContext = useContext(AuthContext);

  return (
    <Routes>
      <Route element={<UserLayout />}>
        <Route path="/" element={<Home />} exact />
        <Route path="/logout" element={<Logout />} exact />
        <Route
          path="/signin"
          element={
            <ConditionalRoute condition={authContext.loggedIn} to="/dashboard">
              <Login />
            </ConditionalRoute>
          }
        />
        <Route
          path="/forget"
          element={
            <ConditionalRoute condition={authContext.loggedIn} to="/dashboard">
              <Forget />
            </ConditionalRoute>
          }
        />
        <Route
          path="/reset-password"
          element={
            <ConditionalRoute condition={authContext.loggedIn} to="/dashboard">
              <ResetPassword />
            </ConditionalRoute>
          }
        />
        <Route
          path="/signup"
          element={
            <ConditionalRoute condition={authContext.loggedIn} to="/dashboard">
              <Register />
            </ConditionalRoute>
          }
        />

        <Route
          path="/dashboard"
          element={
            <ConditionalRoute condition={!authContext.loggedIn} to="/signin">
              <Dashboard />
            </ConditionalRoute>
          }
        />
        <Route
          path="/profile"
          element={
            <ConditionalRoute condition={!authContext.loggedIn} to="/signin">
              <Profile />
            </ConditionalRoute>
          }
        />
        <Route
          path="/plans"
          element={
            <ConditionalRoute condition={!authContext.loggedIn} to="/signin">
              <Plans />
            </ConditionalRoute>
          }
        />
        <Route
          path="/services/:slug/plans"
          element={
            <ConditionalRoute condition={!authContext.loggedIn} to="/signin">
              <Plans />
            </ConditionalRoute>
          }
        />
        <Route
          path="/orders/:plan_id/create"
          element={
            <ConditionalRoute condition={!authContext.loggedIn} to="/signin">
              <OrderNewService />
            </ConditionalRoute>
          }
        />
        <Route
          path="/tickets"
          element={
            <ConditionalRoute condition={!authContext.loggedIn} to="/signin">
              <Tickets />
            </ConditionalRoute>
          }
        />
        <Route
          path="/tickets/create"
          element={
            <ConditionalRoute condition={!authContext.loggedIn} to="/signin">
              <CreateTicket />
            </ConditionalRoute>
          }
        />
        <Route
          path="/tickets/:ticket_id"
          element={
            <ConditionalRoute condition={!authContext.loggedIn} to="/signin">
              <ViewTicket />
            </ConditionalRoute>
          }
        />
        <Route
          path="/orders"
          element={
            <ConditionalRoute condition={!authContext.loggedIn} to="/signin">
              <Orders />
            </ConditionalRoute>
          }
        />
        <Route
          path="/orders/:order_id"
          element={
            <ConditionalRoute condition={!authContext.loggedIn} to="/signin">
              <Order />
            </ConditionalRoute>
          }
        />
        <Route
          path="/my-services"
          element={
            <ConditionalRoute condition={!authContext.loggedIn} to="/signin">
              <MyServices />
            </ConditionalRoute>
          }
        />
        <Route path="/verification" element={<Verification />} />
      </Route>

      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export { AppRoutes };
