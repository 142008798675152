import { MainComponent } from "../main";
import { Loading, SectionTitleComponent, RequestStatusComponent } from "../general";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { ShowErrors } from "../../../../helpers/extractErrors";
import AuthService from "../../../../services/AuthService";
import UserService from "../../../../services/UserService";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const swl = withReactContent(Swal)

const Orders = () => {
  const [loading, setLoading] = useState(true);
  const [orders, setOrders] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const getOrders = async () => {
      const [response] = await Promise.all([UserService.getOrders()]);
      setOrders(response.orders);
      setLoading(false);
    };

    getOrders();
  }, []);

  const OrderRow = (order) => {
    return (
      <tr key={order.id}>
        <td>{order.id}</td>
        <td>{order.uuid.substring(0, 6)}</td>
        <td>{order.name}</td>
        <td>{order.created_at}</td>
        <td>
          <RequestStatusComponent status={order.status} statusName={order.status_name}/>
        </td>
        <td>
          {
              <button className="btn btn-primary btn-sm" onClick={() => showDetails(order.uuid)}>Details</button>
          }
        </td>
      </tr>
    );
  }

  const showDetails = (order_id) => {
    navigate(`/orders/${order_id}`);
  };
  if (loading) return "loading ...";

  return (
    <div className="hero-replace">
      <div className="container">
        <SectionTitleComponent>Orders</SectionTitleComponent>
        <div className="row">
          <div className="col-sm-12">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Number</th>
                  <th>Title</th>
                  <th>Creation Time</th>
                  <th>Status</th>
                  <th>Operation</th>
                </tr>
              </thead>
              <tbody>
                {
                  orders.length == 0 ? 'You have no orders yet.' :
                  orders.map(order => {
                    return OrderRow(order);
                  })
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Orders;
