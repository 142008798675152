import { MainComponent } from "../main";
import { Loading, SectionTitleComponent } from "../general";
import { Link, useNavigate, useLocation } from "react-router-dom";
import AuthService from "../../../../services/AuthService";
import { useContext, useState } from "react";
import { ShowErrors } from "../../../../helpers/extractErrors";
import { AuthContext } from "../../../context/authContext";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const swl = withReactContent(Swal);

const Profile = () => {
  const {user} = useContext(AuthContext);

  console.log(user.email)

  const navigate = useNavigate();
  const [waiting, setWaiting] = useState(false);
  const [fail, setFail] = useState(null);

  const [email, setEmail] = useState(user.email);
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState(user.name);

  const handleSubmit = (event) => {
    event.preventDefault();

    setWaiting(true);
    setFail(null);

    let data = {
      email,
      password,
      username,
    };

    AuthService.profile(data)
      .then(
        (response) => {
          if (response?.status == "success") {
            swl.fire({
              title: (
                <strong>Your profile has been updated successfully!</strong>
              ),
              icon: "success",
            });
          } else {
            swl.fire({
              title: <strong>Unable to update your profile!</strong>,
              icon: "error",
            });
          }
        },
        (error) => {
          const response = error.response;
          setFail(response.data);
        }
      )
      .finally(() => {
        setWaiting(false);
      });
  };

  return (
    <div className="hero-replace">
      <div className="container">
        <SectionTitleComponent>Update Profile</SectionTitleComponent>

        <div className="row">
          <MainComponent>
            <div
              className="col-lg-8 mt-5 mt-lg-0"
              data-aos="fade-left"
              data-aos-delay="200"
            >
              <form
                method="post"
                role="form"
                className="react-form"
                onSubmit={handleSubmit}
              >
                <div className="row">
                  <div className="col-md-6 form-group mt-3 mt-md-0">
                    <input
                      className="form-control"
                      name="username"
                      id="username"
                      placeholder="Username"
                      required
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                    />
                  </div>
                  <div className="col-md-6 form-group mt-3 mt-md-0">
                    <input
                      type="password"
                      className="form-control"
                      name="password"
                      id="password"
                      placeholder="Password"
                      required
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-md-6 form-group">
                    <input
                      type="text"
                      name="email"
                      className="form-control"
                      id="email"
                      placeholder="Email"
                      required
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                </div>
                <div className="mt-3">
                  <Loading waiting={waiting} />
                  <ShowErrors fail={fail} />
                </div>

                <div className="text-right mt-3">
                  <button className="btn btn-primary" type="submit">
                    Update Profile
                  </button>
                </div>
              </form>
            </div>
          </MainComponent>
        </div>
      </div>
    </div>
  );
};

export default Profile;
