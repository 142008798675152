import axios from '../utils/axios';
import AuthService from './AuthService'
import  secureLocalStorage  from  "react-secure-storage";

class UserService {
  getServices() {
    return axios.get("/services").then((response) => {
      secureLocalStorage.setItem("services", JSON.stringify(response.data.services));
      return response.data.services;
    });
  }

  async findItemBySlug(object, slug) {
    const data = JSON.parse(secureLocalStorage.getItem(object));
    const found = data.filter((item) => {return item.name == slug});
    return found[0].uuid;
  }

  async getPlans(slug) {
    if (slug !== 'all') {
      const uuid = await this.findItemBySlug('services', slug);
      return await axios.get("/services/" + uuid + "/plans").then((response) => {
        return response.data.plans;
      });
    } else {
      return await axios.get("/plans").then((response) => {
        return response.data.plans;
      });
    }
  }

  async getPlanInfo(uuid) {
    return await axios.get("/plans/" + uuid).then((response) => {
      return response.data;
    });
  }

  /****** TICKETS **********/
  async getTickets() {
    return await axios.get("/tickets").then((response) => {
      return response.data;
    });
  }

  async saveTicket(data) {
    return await axios.post("/tickets/create", data).then((response) => {
      return response.data;
    });
  }

  async getTicketInfo(uuid) {
    return await axios.get("/tickets/" + uuid).then((response) => {
      return response.data;
    });
  }

  async sendAnswer(uuid, data) {
    return await axios.post(`/tickets/${uuid}/answer`, data).then((response) => {
      return response.data;
    });
  }

  async getTicketPriorities() {
    return await axios.get("/tickets/priorities").then((response) => {
      return response.data;
    });
  }

  async getTicketMessageFile(id) {
    return await axios.get(`/tickets/messages/${id}/file`).then((response) => {
      return response.data;
    });
  }

  /****** ORDERS **********/
  async getOrders() {
    return await axios.get("/orders").then((response) => {
      return response.data;
    });
  }

  async getOrderInfo(uuid) {
    return await axios.get("/orders/" + uuid).then((response) => {
      return response.data;
    });
  }

  async getPeriods(uuid) {
    return await axios.get("/periods/" + uuid).then((response) => {
      return response.data;
    });
  }

  async sendRequest(uuid, data) {
    return await axios.post("/plans/" + uuid + "/request", data).then((response) => {
      return response.data;
    });
  }

  async registerTrackingCode(uuid, data) {
    return await axios.post("/orders/" + uuid + "/register-tracking-code", data).then((response) => {
      return response.data;
    });
  }
}

export default new UserService();