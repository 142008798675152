import { MainComponent } from "../main";
import { Loading, SectionTitleComponent } from "../general";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { ShowErrors } from "../../../../helpers/extractErrors";
import AuthService from "../../../../services/AuthService";
import UserService from "../../../../services/UserService";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const swl = withReactContent(Swal)

const Tickets = () => {
  const [loading, setLoading] = useState(true);
  const [tickets, setTickets] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const getTickets = async () => {
      const [response] = await Promise.all([UserService.getTickets()]);
      setTickets(response.tickets);
      setLoading(false);
    };

    getTickets();
  }, []);

  const getStatusClassName = (status) => {
    switch(status) {
      case 'init':
        return 'badge bg-warning';
      case 'answered_by_user':
        return 'badge bg-primary';
      case 'answered_by_admin':
        return 'badge bg-success';
      case 'referred':
        return 'badge bg-info';
      case 'closed':
        return 'badge bg-dark';
    }
  }

  const showDetails = (ticket_id) => {
    navigate(`/tickets/${ticket_id}`);
  };

  if (loading) return "loading ...";

  return (
    <div className="hero-replace">
      <div className="container">
        <SectionTitleComponent>Tickets</SectionTitleComponent>
        <div className="row">
          <div className="col-sm-12">
            <div className="float-start my-2">
              <Link className="btn btn-primary" to="/tickets/create">Create new ticket</Link>
            </div>
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Number</th>
                  <th>Subject</th>
                  <th>Creation Time</th>
                  <th>Priority</th>
                  <th>Status</th>
                  <th>Operation</th>
                </tr>
              </thead>
              <tbody>
                {
                  tickets.length == 0 ? 'You have no ticket yet.' :
                  tickets.map(ticket => {
                    return (
                      <tr key={ticket.id}>
                        <td>{ticket.id}</td>
                        <td>{ticket.uuid.substring(0, 6)}</td>
                        <td>{ticket.title}</td>
                        <td>{ticket.created_at}</td>
                        <td>{ticket.priority_name}</td>
                        <td><span className={getStatusClassName(ticket.status)}>{ticket.status_name}</span></td>
                        <td>
                          {
                              <button className="btn btn-primary btn-sm" onClick={() => showDetails(ticket.uuid)}>Details</button>
                          }
                        </td>
                      </tr>
                    )
                  })
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tickets;
