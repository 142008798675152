import { Checkbox, FormControlLabel } from "@mui/material"

const ProtocolComponent = ({protocols, currentProtocols, handleProtocols}) => {
    return (
        <div className="row mt-3 g-3 align-items-center">
            <div className="col-auto">
            <label className="col-form-label">Protocols</label>
            </div>
            <div className="col-md-4">
            {protocols.map((item) => (
                <FormControlLabel
                key={item.id}
                control={
                    <Checkbox
                    key={item.id}
                    checked={currentProtocols.includes(parseInt(item.unique_id))}
                    value={item.unique_id}
                    name={item.name}
                    onChange={(e) => handleProtocols(e.target)}
                    />
                }
                label={item.name}
                />
            ))}
            </div>
        </div>
    )
}

export default ProtocolComponent