import { Checkbox, FormControlLabel } from "@mui/material";

const ExtraFeaturesComponent = (props) => {
  return (
    <div className="row mt-3 g-3 align-items-center">
      <div className="col-auto">
        <label className="col-form-label">Extra Features</label>
      </div>
      <div className="col-md-4">
        {typeof props.plan.features.shop !== "undefined" ? (
          <FormControlLabel
            control={
              <Checkbox
                disabled={props.plan.features?.enum == "shared" ? true: false}
                checked={props.shop}
                value="shop"
                name="shop"
                onChange={(e) => props.handleShop(e.target)}
              />
            }
            label="Shop"
          />
        ) : (
          ""
        )}

        {typeof props.plan.features.bot !== "undefined" ? (
          <FormControlLabel
            control={
              <Checkbox
                disabled={props.plan.features?.enum == "shared" ? true: false}
                checked={props.bot}
                value="bot"
                name="bot"
                onChange={(e) => props.handleBot(e.target)}
              />
            }
            label="Telegram bot"
          />
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default ExtraFeaturesComponent;
