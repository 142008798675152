import { MainComponent } from "../main";
import { SectionTitleComponent } from "../general";

const Dashboard = () => {
    return (
        <div className="hero-replace">
            <div className="container">
                <SectionTitleComponent>User Area</SectionTitleComponent>

                <div className="row">
                    <MainComponent>
                        <div className="col-lg-8 mt-5 mt-lg-0" data-aos="fade-left" data-aos-delay="200">
Welcome to {process.env.REACT_APP_APP_NAME}.
                        </div>

                    </MainComponent>
                </div>
            </div>
        </div>
    );
}

export default Dashboard;