import { ShowErrors } from "../../../../../helpers/extractErrors"
import { Loading } from "../index";

const SummaryComponent = (props) => {
    return (
        <div
              className="col-lg-4 mt-5 mt-lg-0"
              data-aos="fade-right"
              data-aos-delay="200"
            >
            <div className="card">
                <div className="card-body">
                <h5 className="card-title">Order Summary</h5>

                <table className="table table-striped">
                    <thead>
                    <tr>
                        <th>Title</th>
                        <th>Amount</th>
                        <th>Price</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        typeof props.plan.features.location === "undefined" ?
                        "" :
                        <tr>
                            <td>Location</td>
                            <td>{props.country.name}</td>
                            <td>{parseFloat(props.countryPrice).toLocaleString()}</td>
                        </tr>
                    }
                    {
                        typeof props.plan.features.shop === "undefined" ?
                        "" :
                        <tr>
                            <td>Shop</td>
                            <td>{props.shop ? 'Yes' : 'No'}</td>
                            <td>{parseFloat(props.shopPrice).toLocaleString()}</td>
                        </tr>
                    }
                    {
                        typeof props.plan.features.shop === "undefined" ?
                        "" :
                        <tr>
                            <td>Telegram bot</td>
                            <td>{props.bot ? 'Yes' : 'No'}</td>
                            <td>{parseFloat(props.botPrice).toLocaleString()}</td>
                        </tr>
                    }
                    <tr>
                        <td>Protocols</td>
                        <td style={{ wordBreak: 'break-all' }}>{props.protocolsString.join(",")}</td>
                        <td>{parseFloat(props.protocolPrice).toLocaleString()}</td>
                    </tr>
                    <tr>
                        <td>Online user count</td>
                        <td>{props.onlineUsers}</td>
                        <td>{props.onlineUsersPrice}</td>
                    </tr>
                    <tr>
                        <td>Registered user count</td>
                        <td>{props.registeredUsers}</td>
                        <td>{parseFloat(props.registeredUsersPrice).toLocaleString()}</td>
                    </tr>
                    </tbody>
                </table>

                <div className="mt-3">
                    <Loading waiting={props.waiting} />
                    <ShowErrors fail={props.fail} />
                </div>
                <div className="text-right mt-3 d-flex justify-content-between">
                    <button className="btn btn-primary" onClick={() => props.handleOrder()} type="submit">
                    Save
                    </button>
                    <button className="btn btn-success">{props.price} USDT(TETHER)</button>
                </div>
                </div>
            </div>
        </div>
    )
}

export default SummaryComponent