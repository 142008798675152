import { AnnouncementComponent } from "./sections/announcement";
import { HelpfulLinksComponent } from "./sections/helpfulLinks";
import { NewsletterComponent } from "./sections/newsletter";
import { OurServicesComponent } from "./sections/ourServices";
import { CopyrightComponent } from "./sections/copyright";
import { BackToTopComponent } from "./sections/backToTop";
import { PreLoaderComponent } from "./sections/preLoader";

const FooterComponent = () => {
  return (
    <>
      <footer id="footer">
        {/* <div className="footer-top">
          <div className="container">
            <div className="row">
              <AnnouncementComponent />
              <HelpfulLinksComponent />
              <OurServicesComponent />
              <NewsletterComponent />
            </div>
          </div>
        </div> */}
        <CopyrightComponent />
      </footer>

      <BackToTopComponent/>

      <PreLoaderComponent/>
    </>
  );
};

export default FooterComponent;
