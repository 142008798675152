import { Loading, SectionTitleComponent } from "../general";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import UserService from "../../../../services/UserService";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import {RequestStatusComponent} from "../general";

const swl = withReactContent(Swal)

const Order = () => {
  const [loading, setLoading] = useState(true);
  const [order, setOrder] = useState("");
  const [trackingCode, setTrackingCode] = useState("");
  const { order_id } = useParams();

  const getOrderInfo = async () => {
    const [response] = await Promise.all([UserService.getOrderInfo(order_id)]);

    setOrder(response.data);
    setTrackingCode(response.data.tracking_code);
    setLoading(false);
  };

  useEffect(() => {
    getOrderInfo();
  }, []);

  const handleSubmit = () => {
    swl.fire({
      title: <strong>Please enter your tracking code in the box below and press enter.</strong>,
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      confirmButtonText: 'Check',
      cancelButtonText: 'Cancel',
      showLoaderOnConfirm: true,
      preConfirm: async (code) => {
        if (code === '') {
          swl.showValidationMessage(
            `Enter your tracking code`
          )
          return false;
        }
        
        UserService.registerTrackingCode(order_id, {tracking_code: code}).then((response) => {
          if (response?.status == "success") {
            setTrackingCode(code);
            getOrderInfo();
            swl.fire({
              title: <strong>Your tracking code has been registered successfully!</strong>,
              icon: 'success'            
            });
          } else {
            swl.fire({
              title: <strong>There is a problem with registering your tracking code, please try again!</strong>,
              icon: 'error'            
            });
          }
        })
      },
    });
  };

  const CardNumberDetails = () => {
    return (
      <div className="card" data-aos="fade-left">
        <div className="card-body">
          Please pay your order price to this wallet:
          <span className="badge bg-info blockquote h3" style={{fontSize: '18px'}}>{process.env.REACT_APP_CARD_NUMBER}</span>
          And send us your tracking code by clicking the button.
        </div>
      </div>
    )
  }

  const OrderDetials = ({details}) => {
    return (
      <>
        {
          (typeof details.location != "undefined") ?
          <span><b>Location:</b> {details.location}</span> : 
          ''
        }
        <br/>
        <span><b>Online user count:</b> {details.online_users.toLocaleString()}</span><br/>
        <span><b>Registered user count:</b> {details.registered_users.toLocaleString()}</span><br/>
        <span><b>Period:</b> {details.period}</span><br/>
        {
          (typeof details.shop != "undefined") ?
          <span><b>Shop:</b> {details.shop}</span> : 
          ''
        }
        <br/>
        {
          (typeof details.bot != "undefined") ?
          <span><b>Telegram bot:</b> {details.bot}</span> : 
          ''
        }
        <br/>
        {
          (typeof details.protocols != "undefined") ?
          <span><b>Protocols:</b> {details.protocols.join(',')}</span> : 
          ''
        }
      </>
    )
  }

  const OperationColumn = ({order}) => {
    //price is free or status is pending    
    if (!trackingCode || order.status == 'rejected')
      return <button className="btn btn-primary btn-sm" onClick={() => handleSubmit()}>Register tracking code</button>

    if (order.status == 'approved')
      return '';

    return <span className="badge bg-warning">Waiting for admin check</span>;
  }

  if (loading) return "loading ...";

  return (
    <div className="hero-replace">
      <div className="container">
        <SectionTitleComponent>Your order</SectionTitleComponent>
        <div className="row">
          <div className="col-sm-12">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>Number</th>
                  <th>Title</th>
                  <th>USDT(TETHER)</th>
                  <th>Details</th>
                  <th>Creation Time</th>
                  <th>Status</th>
                  <th>Operation</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{order.uuid.substring(0, 6)}</td>
                  <td>{order.name}</td>
                  <td>{order.price}</td>
                  <td>
                      <OrderDetials details={order.details}/>
                  </td>
                  <td>{order.created_at}</td>
                  <td>
                    <RequestStatusComponent status={order.status} statusName={order.status_name}/>
                  </td>
                  <td>
                      <OperationColumn order={order}/>
                  </td>
                </tr>
              </tbody>
            </table>
            
            {
              order.price != 0 ?
              <CardNumberDetails/> :
              ''
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default Order;
