import { Link, useNavigate } from "react-router-dom";
import UserService from "../../../../services/UserService";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

const swl = withReactContent(Swal);

const Plan = ({
  name,
  period_id,
  period,
  price,
  features,
  uuid,
  country,
  protocols,
}) => {
  const navigate = useNavigate();

  const handleFreePlan = (plan_id, features, period_id) => {
    const data = {
      protocols: features.protocols,
      online_users: features.online_users,
      registered_users: features.registered_users,
      period: period_id,
    };

    //add country to data if plan has country
    if (typeof features.location !== "undefined") {
      data.location = features.location;
    }

    UserService.sendRequest(plan_id, data).then(
      (response) => {
        if (response?.status == "success") {
          const { data } = response;

          swl.fire({
            title: <strong>Your order has been saved!</strong>,
            icon: "success",
            willClose: () => {
              navigate("/orders", {
                state: {
                  order_id: data.order_id,
                },
              });
              return false;
            },
          });
        }
      },
      (error) => {
        const response = error.response;
        if (response.status == 403) {
          swl.fire({
            title: <strong>You have used free plan already.</strong>,
            icon: "error",
          });
        }
      }
    );
  };

  return (
    <div className="col-lg-3 col-md-6 mt-4 mt-lg-0">
      <div className="box" data-aos="zoom-in" data-aos-delay="400">
        {/* <span className="advanced">پیشرفته</span> */}
        <h3>{name}</h3>
        {uuid == "without-id" ? (
          <>
            <p>
              By purchasing a license of our software, you will gain access to
              our specific services.
            </p>
            <a
              href="https://t.me/Cloudius_Support"
              target="_blank"
              className="btn-buy"
            >
              Contact Us{" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-send"
                viewBox="0 0 16 16"
              >
                <path d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576zm6.787-8.201L1.591 6.602l4.339 2.76z" />
              </svg>
            </a>
          </>
        ) : (
          <>
            <h4>
              {parseFloat(price).toLocaleString()}
              <span style={{paddingLeft: "10px"}}>USDT</span>
              {/* <span> {period}</span> */}
              <span>(Tether)</span>
            </h4>
            <ul style={{fontSize: '12px'}}>
              {country ? <li>Location : {country}</li> : ""}
              <li>Shop :{features?.shop ? "Yes" : "No"}</li>
              <li>Telegram bot :{features?.bot ? "Yes" : "No"}</li>
              <li style={{ "wordWrap": "break-word" }}>
                Protocols : {protocols.join(",")}
              </li>
              <li>Online User Count : {features.online_users.min}{price != 0.0 ? `-${features.online_users.max}` : '' }</li>
              <li>Registered User Count : {features.registered_users.min}{price != 0.0 ? `-${features.registered_users.max}` : '' }</li>
            </ul>
            <div className="btn-wrap">
              {price != 0.0 ? (
                <Link to={"/orders/" + uuid + "/create"} className="btn-buy">
                  Buy
                </Link>
              ) : (
                <a
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    handleFreePlan(uuid, features, period_id);
                  }}
                  className="btn-buy"
                >
                  Buy
                </a>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Plan;
